import { template as template_a6bff60e6aa741b4b4e21f6c8773cea8 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_a6bff60e6aa741b4b4e21f6c8773cea8(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
