import { template as template_46f99a39642e4fbfbe7b437af24fe9ea } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_46f99a39642e4fbfbe7b437af24fe9ea(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
