import { template as template_11e2b7e823e94cf4ae0ae935b888e042 } from "@ember/template-compiler";
const SidebarSectionMessage = template_11e2b7e823e94cf4ae0ae935b888e042(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
