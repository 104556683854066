import { template as template_67a07fe40b0a47b6ab9bc5935a38a361 } from "@ember/template-compiler";
const FKControlMenuContainer = template_67a07fe40b0a47b6ab9bc5935a38a361(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
