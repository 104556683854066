import { template as template_d4dd6e34634748a5a4d77aef2677c3ba } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_d4dd6e34634748a5a4d77aef2677c3ba(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
